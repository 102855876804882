// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-node-js-jsx": () => import("./../src/pages/node-js.jsx" /* webpackChunkName: "component---src-pages-node-js-jsx" */),
  "component---src-pages-react-js-jsx": () => import("./../src/pages/react-js.jsx" /* webpackChunkName: "component---src-pages-react-js-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../src/templates/blog-template.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */)
}

